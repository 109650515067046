import { Injectable } from '@angular/core';
import { AppStateService, HostStateService, VideoPreloadService, getStepData } from '@yoyo/services';
import { ComponentTypes, AppHostTypes, Reaction,AppHost  } from '@yoyo/types';

@Injectable({
  providedIn: 'root',
})
export class LandingService {
  current_reaction: Reaction;
  current_host_config: AppHost;


  constructor(private app_state: AppStateService, 
              private host_state: HostStateService,
              private videoService: VideoPreloadService,
              private getStepData: getStepData
          ) {
    this.current_host_config = this.host_state.currentHostConfig;
    this.current_reaction = this.app_state.current_reaction;
  }

  setAppLanding(appScope: AppHostTypes): ComponentTypes {
    console.log('setting app landing page for app type: ' + appScope);
    if (appScope === 'getApp'){
      const setVideo = this.sourceVideoURLGetSet(appScope)
      if (setVideo != 'false') {
        this.videoService.preloadVideo(this.app_state.reactionVideoURL)
          .catch(err=>{
            if (setVideo == 'user') {
              console.warn('User video is not valid.  Attempting host')
             if(this.current_host_config?.revealVideoDefaults?.defaultRevealURL) {
              this.app_state.reactionVideoURL = this.current_host_config.revealVideoDefaults.defaultRevealURL
              const stepTo = this.getStepData.getStepNumber('primaryLand');
              this.app_state.reactionStepValue$.next(stepTo)
              this.videoService.preloadVideo(this.app_state.reactionVideoURL)
              .catch(err=>{
                const stepTo = this.getStepData.getStepNumber('secondaryLand');
                this.app_state.reactionStepValue$.next(stepTo)
              })
             } else {
              const stepTo = this.getStepData.getStepNumber('secondaryLand');
              this.app_state.reactionStepValue$.next(stepTo)
             }
            } else {
              const stepTo = this.getStepData.getStepNumber('secondaryLand');
              this.app_state.reactionStepValue$.next(stepTo)
            }
          })
        return 'primaryLand';
      } else {
        return 'secondaryLand';
      }
    } else {
      const setVideo = this.sourceVideoURLGetSet(appScope)
      if (setVideo) {
        this.videoService.preloadVideo(this.app_state.reactionVideoURL)
          .catch(err=>{
            const stepTo = this.getStepData.getStepNumber('secondaryLand');
            this.app_state.reactionStepValue$.next(stepTo)
          });
        return 'primaryLand';
      } else {
        if (this.current_reaction?.response?.msg){
          return 'secondaryLand'
        } else {
          return 'thirdLand'
        }
      }
    }
  }


  sourceVideoURLGetSet(appScope: AppHostTypes): string{
    console.log('Determine video URL for app type: ' + appScope);
    if (appScope == 'getApp') {
      if (this.current_reaction?.content?.videoURL){
        console.warn('Reaction Video URL: '+ this.current_reaction.content.videoURL);
        this.app_state.reactionVideoURL = this.current_reaction.content.videoURL;
        return 'user'
      } else if (this.current_host_config?.revealVideoDefaults?.defaultRevealURL){
          this.app_state.reactionVideoURL = this.current_host_config.revealVideoDefaults.defaultRevealURL
          console.warn('Using global hosted video URL');
          return 'host'
        } else {
          console.log('no video found!!');
          return 'false'
        }
    } else {
      if (this.current_reaction?.response?.video){
        this.app_state.reactionVideoURL = this.current_reaction.response.video
        return this.app_state.reactionVideoURL
      } else {
        return 'false'
      }
    }
  }
}
