import { Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, AfterViewInit } from '@angular/core';
import { AppHost, Reaction } from '@yoyo/types';
import * as RecordRTC from 'recordrtc';
import { ReactionService, ResizeService } from '@yoyo/services';
import { manualDelay } from '@yoyo/shared';
import { Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'webcam-recorder',
  templateUrl: './webcamRecorder.component.html',
})
export class WebcamRecorderComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  reaction_data: Reaction;
  @ViewChild('webcam_video') webcam_video: ElementRef<HTMLVideoElement>;
  @ViewChild('canvasElement') canvasElement: ElementRef<HTMLCanvasElement>;

  // Record RTC
  private stream: MediaStream;
  private recorder: any;
  devicesError = false;
  host_config: AppHost;
  private currentTime: number;
  private prevTime: number;
  private timer: number = 0;
  resizeSubscription: Subscription;
  videoSrcSafe: SafeUrl;
  videoSrcURL: string;
  private debounceTimeout: any;
  private playBackActive: boolean;
  private recordingActive: boolean;

  constructor(
    private rs: ReactionService,
    private resizeService: ResizeService,
    private domSanitizer: DomSanitizer,
  ) { }

  @Input() startStreaming: boolean;
  @Input() startRecording: boolean;
  @Input() imageBeforeRecording: string;
  @Input() snapShotTrigger: boolean;
  @Input() time: number;
  @Input() duration: number;
  @Input() recPlayback: boolean;
  @Input() pausePlayback: boolean;
  @Input() resumePlayback: boolean;
  @Output() currentTimeOfWebCamRecording = new EventEmitter<string>();
  @Output() finshedRecordingWebcam = new EventEmitter<boolean>();
  @Output() position = new EventEmitter<any>();
  @Output() snapShotImg = new EventEmitter<any>();
  @Output() recPlaybackDone = new EventEmitter<boolean>();
  @Output() recPlaybackCurrentTime = new EventEmitter<number>();

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes?.startStreaming?.currentValue) {
      await this.startStream();
    }

    if (changes?.startRecording?.currentValue) {
      this.startReactionRecording();
    } else if (changes?.startRecording?.currentValue === false) {
      if (this.recorder) {
        this.timer = 0;
        this.webcam_video.nativeElement.currentTime = 0;
        this.recorder.stopRecording(this.processVideo.bind(this));
        this.webcam_video.nativeElement.pause();
        this.webcam_video.nativeElement.srcObject = null;
        this.webcam_video.nativeElement.load();
      }
    }

    if (changes?.snapShotTrigger?.currentValue) {
      this.captureFrame();
    }

    if (changes?.recPlayback?.currentValue) {
      this.playBack();
    }

    if (changes?.pausePlayback?.currentValue) {
      console.log('I see pause')
      this.pausePlayBackAction();
    }

    if (changes?.resumePlayback?.currentValue) {
      console.log('I see resume')
      this.resumePlayBackAction();
    }
  }

  private async startStream(): Promise<void> {
    console.log('Starting stream initiated');
    try {
      this.playBackActive =false
      if (this.recorder) {
        return;
      }
      const s = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      this.stream = s;
      const selected_webcam_stream: HTMLVideoElement = this.webcam_video.nativeElement;
      selected_webcam_stream.srcObject = this.stream;
      selected_webcam_stream.muted = !selected_webcam_stream.muted;
      selected_webcam_stream.autoplay = !selected_webcam_stream.autoplay;
    } catch (error) {
      console.error('Error starting stream:', error);
    }
  }

  async processVideo(): Promise<void> {
    this.stopMedia();
    const blob = await this.recorder.getBlob();
    this.recorder = null;
    await this.onReactionComplete(blob);
  }

  async onReactionComplete(value: Blob): Promise<void> {
    try {
      this.rs.reaction_recording_blob = value;
      this.recorder = null;
      this.stream = null;
      if (this.rs.reaction_recording_blob instanceof Blob) {
        const objectURL = URL.createObjectURL(this.rs.reaction_recording_blob);
        this.videoSrcSafe = this.domSanitizer.bypassSecurityTrustUrl(objectURL);
        this.videoSrcURL = objectURL;
        const selected_webcam_stream: HTMLVideoElement = this.webcam_video.nativeElement;
        selected_webcam_stream.src = this.videoSrcURL;
        selected_webcam_stream.pause();
      }
      this.finshedRecordingWebcam.emit(true);
    } catch (err) {
      console.error('Error on reaction complete:', err);
    }
  }

  private stopMedia(): void {
    if (this.recorder && this.stream) {
      this.stream.getAudioTracks().forEach((track) => track.stop());
      this.stream.getVideoTracks().forEach((track) => track.stop());
      this.recordingActive = false;
    }
  }

  async ngOnInit() {
    try {
      this.devicesError = await this.checkEnumerateDevices();
      if (this.devicesError) {
        alert('Device not supported!');
      }
      await manualDelay(6000);
    } catch (error) {
      console.error('Error on init:', error);
    }
  }

  ngAfterViewInit(): void {
    if (this.webcam_video) {
      const rect = this.webcam_video.nativeElement.getBoundingClientRect();
      this.position.emit(rect);
    }
    this.resizeSubscription = this.resizeService.onResize(this.webcam_video.nativeElement).subscribe(() => {
      const rect = this.webcam_video.nativeElement.getBoundingClientRect();
      this.position.emit(rect);
    });
  }

  private startReactionRecording(): void {
    this.playBackActive =false
    this.recordingActive = true;
    this.currentTime = 0;
    this.prevTime = 0;
    this.recorder = new RecordRTC(this.stream, {
      type: 'video',
      mimeType: 'video/webm;codecs=h264',
      audioBitsPerSecond: 128000,
      videoBitsPerSecond: 128000,
      disableLogs: true
    });
    this.recorder.startRecording();
    setInterval(() => {
      this.timer += 0.5;
      this.currentTimeOfWebCamRecording.emit(this.timer.toString());
    }, 500);
  }

    metaLoaded(){
      const videoElement: HTMLVideoElement = this.webcam_video.nativeElement;
      const duration = videoElement.duration
      //console.log('meta thinks the lenght is: ' + duration);
    }


  playBack() {
    console.log('web cam playback started');
    this.playBackActive =true;
    this.currentTime = 0;
    this.prevTime = 0;
    this.recordingActive = false;
    const selected_webcam_stream: HTMLVideoElement = this.webcam_video.nativeElement;
    selected_webcam_stream.src = this.videoSrcURL;
    selected_webcam_stream.load();
    selected_webcam_stream.muted = false;
    selected_webcam_stream.play();

    selected_webcam_stream.onended = () => {
      this.recPlaybackDone.emit(true);
    };

    selected_webcam_stream.onloadedmetadata = () => {
      console.log('Video duration:', selected_webcam_stream.duration);
    }

  }

  pausePlayBackAction() {
    if (!this.playBackActive){
      console.log('wasgoing to say pause but not tyet my job')
    } else {
      console.log('told it to puase');
      const selected_webcam_stream: HTMLVideoElement = this.webcam_video.nativeElement;
      selected_webcam_stream.pause();
    }
  }

  resumePlayBackAction() {
    const selected_webcam_stream: HTMLVideoElement = this.webcam_video.nativeElement;
    selected_webcam_stream.play();
  }


  captureFrame() {
    const video = this.webcam_video.nativeElement;
    const canvas = this.canvasElement.nativeElement;
    const context = canvas.getContext('2d');

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const dataURL = canvas.toDataURL('image/png');
    this.snapShotImg.emit(dataURL);
  }

  async checkEnumerateDevices() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      if (Array.isArray(devices) && devices.length) {
        return false;
      } else {
        return true;
      }
    } catch (err) {
      return true;
    }
  }

  @HostListener('unloaded')
  ngOnDestroy() {
    //console.warn('Go for destroy');
    this.cleanup();
  }

  private cleanup() {
    if (this.recorder) {
      this.recorder.destroy();
      this.recorder = null;
    }
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
      this.stream = null;
    }
  }

  updateCurrentTime() {
    if (this.playBackActive){
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
  
      this.debounceTimeout = setTimeout(() => {
        if (this.webcam_video) {
          this.currentTime = this.webcam_video.nativeElement.currentTime;
          let increment = this.currentTime - this.prevTime;
          this.prevTime = this.currentTime;
        //  console.log('time output are: ' + this.currentTime);
          this.recPlaybackCurrentTime.emit(increment);
        }
      }, 100);
    }
  }
}
