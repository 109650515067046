import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PrimaryLayout } from '@yoyo/layouts';
import { CreateReactionComponent } from '@yoyo/pages';

const routes: Routes = [
  {
    path: ':frame',
    component: PrimaryLayout,
    children: [
      {
        path: ':id',
        component: CreateReactionComponent,
      },
    ],
  },
  { path: '**', pathMatch: 'full', redirectTo: 'v2' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
