import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationCancel, NavigationError, NavigationStart } from '@angular/router';
import { HostStateService, AppStateService, TelemetryService, AuthService, ReactionService } from '@yoyo/services';
import { filter } from 'rxjs/operators';
//import { FormBuilder, FormGroup } from '@angular/forms';    //this appear unesaccary as constuctor not nescassary

@Component({
  selector: 'app-root',
  template: `
    <div *ngIf="!isResolvingAppState">
      <router-outlet></router-outlet>
    </div>
    <div *ngIf="isResolvingAppState">
    
    <div class="absolute h-full generic-bg w-full z-50 flex flex-col items-center justify-center">

      <p *ngIf="!entryFailed" class="text-white text-center text-4xl font-large mt-2.5 pb-4">
          Reply.Gift is:
        </p>  
  <p *ngIf="entryFailed" class="text-white text-center text-4xl font-large mt-2.5 pb-4">
    Your gift is ready:
  </p>  

   <p *ngIf="!entryFailed" class="text-white text-center text-2xl font-medium mt-2.5 pb-4">
      Securing the package: 
      <svg *ngIf="!securitySetting" class="text-gray-300 animate-spin inline-block align-middle" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"
          width="24" height="24">
          <path
            d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
            stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"></path>
          <path
            d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
            stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" class="text-gray-900">
          </path>
      </svg>
      <span *ngIf="securitySetting">✓</span>
      <svg *ngIf="securitySetting && !securityValid && securityCheck" class="inline-block align-middle" height="20px" width="20px" viewBox="0 0 330 330">
          <path fill="#FFFFFF" d="M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85
              S80,38.131,80,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M180,234.986V255c0,8.284-6.716,15-15,15
              s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25
              C190,223.162,186.068,230.421,180,234.986z M110,85c0-30.327,24.673-55,55-55s55,24.673,55,55v45H110V85z"/>
      </svg>
    </p>

    <p *ngIf="securityCheck && securitySetting && !entryFailed" class="text-base text-center text-white font-light mt-2.5 mx-3">
      Phone number required to unlock
    </p>
    <p *ngIf="securityCheck && securitySetting && entryFailed" class="text-base text-center text-white font-light mt-2.5 mx-3">
      Pleas use the phone number where this link was SMS'd to
    </p>

      <form *ngIf="securityCheck && securitySetting" #phoneForm="ngForm" class="p-4 flex flex-col items-center">
          <input type="text" name="phoneNum" [(ngModel)]="phoneNum" required
                [disabled]="!securityCheck"
                class="form-input mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md text-gray-700 shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed">
          <button class="mt-4 px-4 py-2 text-white font-semibold rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 disabled:bg-gray-400 disabled:cursor-not-allowed"
                  [ngClass]="{
                              'bg-green-500 shadow-lg': securityCheck,
                              'shadow-none': !securityCheck
                          }"
                  (click)="submitPhone()"
                  >
                  <svg *ngIf="ready2 !== null && securityCheck && !securityValid" class="inline-block align-middle" height="20px" width="20px" viewBox="0 0 330 330">
                    <path fill="#FFFFFF" d="M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85
                          S80,38.131,80,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M180,234.986V255c0,8.284-6.716,15-15,15
                          s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25
                          C190,223.162,186.068,230.421,180,234.986z M110,85c0-30.327,24.673-55,55-55s55,24.673,55,55v45H110V85z"/>
                  </svg>
                
                </button>
      </form>

    <p *ngIf="!entryFailed" class="text-white text-center text-2xl font-medium mt-2.5 pb-4">
      Assigning delivery: 
      <svg *ngIf="ready1 == null" class="text-gray-300 animate-spin inline-block align-middle" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"
          width="24" height="24">
          <path
            d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
            stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"></path>
          <path
            d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
            stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" class="text-gray-900">
          </path>
      </svg>
      <span *ngIf="ready1 !== null">{{ ready1 }}</span>
      <svg *ngIf="ready1 !== null && securityCheck && !securityValid" class="inline-block align-middle" height="20px" width="20px" viewBox="0 0 330 330">
          <path fill="#FFFFFF" d="M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85
              S80,38.131,80,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M180,234.986V255c0,8.284-6.716,15-15,15
              s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25
              C190,223.162,186.068,230.421,180,234.986z M110,85c0-30.327,24.673-55,55-55s55,24.673,55,55v45H110V85z"/>
      </svg>
    </p>
    <p *ngIf="!entryFailed" class="text-white text-center text-2xl font-medium mt-2.5 pb-4">
        Readying unwrap: 
        <svg *ngIf="ready2 == null" class="text-gray-300 animate-spin inline-block align-middle" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"
          width="24" height="24">
          <path
            d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
            stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"></path>
          <path
            d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
            stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" class="text-gray-900">
          </path>
        </svg>
        <span *ngIf="ready2 !== null">{{ ready2 }}</span>
        <svg *ngIf="ready2 !== null && securityCheck && !securityValid" class="inline-block align-middle" height="20px" width="20px" viewBox="0 0 330 330">
            <path fill="#FFFFFF" d="M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85
                S80,38.131,80,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M180,234.986V255c0,8.284-6.716,15-15,15
                s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25
                C190,223.162,186.068,230.421,180,234.986z M110,85c0-30.327,24.673-55,55-55s55,24.673,55,55v45H110V85z"/>
        </svg>
    </p>
    <p  [ngClass]="{'opacity-0': !securitySetting, 'opacity-100 transition-opacity duration-900000': securitySetting}"
    class="text-white text-center text-lg font-medium mt-2.5 pt-2">{{bottomMsg}}</p>
      </div>
    </div>
  `,
})

export class AppComponent implements OnInit {
  isResolvingAppState = true;
  reactionId: string | null = null;
  ready1: string = null;
  ready2: string = null;
  phoneNum: string;
  securitySetting = false     //controls when settings are known
  securityCheck = true;       //Checks are true
  securityValid = false;      //checks are passed
  entryFailed = false;        //entry attempted and failed
  recipentName: string = ''
  bottomMsg: string = ''

  constructor(
    private router: Router,
    private ar: ActivatedRoute,
    private hs: HostStateService,
    private rs: ReactionService,
    private app_state: AppStateService,
    private telemetry: TelemetryService,
    private auth: AuthService,
  ) { }

  async ngOnInit() {
    console.log('App component init');
    this.telemetry.initialise();
    this.telemetry.setInitialTime();
    this.telemetry.startRecordingLoad();
    await this.initialiseApp();
  }

  async initialiseApp() {
    try {
      await this.auth.getToken();
      await Promise.all([this.hostInitialise(), this.reactionInitialise()]);
      this.telemetry.stopRecordingLoad();
      this.telemetry.postTelemetry();  // Assuming postTelemetry returns a promise
      if (this.securityValid || !this.securityCheck) {
        console.log('Host initialisation completed.');
        this.isResolvingAppState = false;
      }
   //   console.log('Both initialisation tasks completed.');
    } catch (e) {
      console.log('Critical Error during initialisation', e);
      this.isResolvingAppState = true;
    }
  }


  async hostInitialise() {
//    console.log('Init host started');
    try {
      const host = document.location.hostname;
      this.hs.setHostName(host);
      const hostConfig = await this.hs.getHostDataFromCloud(host, 'hosts');
      if (hostConfig) {
        this.hs.applyConfiguration(hostConfig);
        this.ready2 = '✓';
      } else {
        console.log('this is bad news');
      }
    } catch (e) {
      console.log('Critical Error: App Config', e);
      throw e;  // Re-throw to ensure the error is caught in initialiseApp
    }
  }



  async reactionInitialise() {
  //  console.log('Init reaction started');
    return new Promise<void>((resolve, reject) => {
      this.reactionId = this.getLastChildRouteParam('id');
    //  console.log('Got reactionid: ' + this.reactionId);
      this.rs.getReactionData(this.reactionId).subscribe(
        async res => {
          //   console.log('Reaction payload: ' + JSON.stringify(res,null,2));
          //const payload = res.payload
          this.app_state.setReaction(res.reaction);
          this.recipentName = this.app_state?.current_reaction?.receiver_details?.first_name;
          this.securitySetting = true;
          this.bottomMsg = 'Thank for waiting ' + this.app_state?.current_reaction?.receiver_details?.first_name + '.  We are almost there.'
          if (this.app_state?.current_reaction?.security?.enabled === false ||
            this.app_state?.current_reaction?.security?.enabled === undefined ||
            this.app_state?.current_reaction?.receiver_details?.phone_number === null ||
            this.app_state?.current_reaction?.receiver_details?.phone_number === undefined
          ) {
            this.securityCheck = false;
          } else {
            this.securityCheck = true;
          }
          this.rs.logAccess(this.reactionId, 'getApp');  // Posts updates back about last accessed etc.
          this.ready1 = '✓';
          resolve();
        },
        error => {
          console.log('well that is no good');
          reject(error);
        }
      );

    });
  }


  submitPhone(): void {
    const reactPhone = this.normalizePhoneNumber(this.app_state?.current_reaction?.receiver_details?.phone_number);
    const inputNumber = this.normalizePhoneNumber(this.phoneNum);
    //console.log('Normalised phone number entered is ' + inputNumber + ' normalised expected: ' + reactPhone);
    if (reactPhone === inputNumber) {
 //     console.log('success');
      this.entryFailed = false
      this.isResolvingAppState = false;
    } else {
      this.entryFailed = true
      this.bottomMsg = 'opps that was not the right phone number';
    }
  }

  getLastChildRouteParam(paramName: string): string | null {
    let route = this.ar.firstChild;
    while (route?.firstChild) {
      route = route.firstChild;
    }
    return route?.snapshot.paramMap.get(paramName) || null;
  }

  checkPhone() {
    console.log('go time');
  }

  normalizePhoneNumber(phoneNumber: string) {
    if (!phoneNumber) {
      return '';
    }
    // Remove all non-numeric characters
    let normalizedNumber = phoneNumber.replace(/[^0-9]/g, '');
    // Optionally, you can add logic to handle international codes
    // For example, if your phone numbers should start with a country code, you can add that logic here

    return normalizedNumber;
  }



}
