import { Component, Output, ElementRef, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { ResizeService } from '@yoyo/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fullTermsAndConditions',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.css']
})
export class viewFullTerms implements AfterViewInit {
  @ViewChild('contentFrame') contentFrame: ElementRef<HTMLVideoElement>;
  @ViewChild('cancelView') cancelView: ElementRef;

  @Output() close = new EventEmitter<void>();

  resizeSubscription: Subscription;

  constructor( private resizeService: ResizeService,) {}

  ngAfterViewInit(): void {
    const rect = this.contentFrame.nativeElement.getBoundingClientRect();
    this.resizeSubscription = this.resizeService.onResize(this.contentFrame.nativeElement).subscribe(() => {
      const rect = this.contentFrame.nativeElement.getBoundingClientRect();
      console.log('Legal content frame position is: ' + JSON.stringify(rect,null,2))
    //  this.cancelView.nativeElement.style.top = rect.bottom;
    //  this.cancelView.nativeElement.style.right = rect.right;
    });
  }

  closeModal() {
    console.log('Close terms');
    this.close.emit();
  }
}
    
