import { Component } from '@angular/core';
import { HostStateService } from '@yoyo/services';
import { AppHost } from '@yoyo/types';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'primary-layout',
  template: `
    <div class="bg flex flex-col h-screen min-h-fit">
      <div class="header flex">
      <app-header class="w-full min-h-full flex"></app-header>
      </div>
      <div class="w-full h-full flex flex-col items-center justify-start p-2 mt-0 md:mt-2 xl:justify-center ">
        <div class="panel w-full max-w-3xl lg:max-w-3xl xl:max-w-4xl rounded-xl xl:min-h-8/10 min-h-[70%] lg:min-h-[85%] ">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .header {
        height: 9.8%;
        min-height: 9.8%;
        max-height: 9.8%;
      }
      @media only screen and (min-width: 1024px) {
        .header {
          height: 9.8%;
          min-height: 9.8%;
          max-height: 9.8%;
        }
      }
    `,
  ],
})

export class PrimaryLayout {
  current_pathname = window.location.pathname;
  background_image: boolean = false;
  current_host_config: AppHost;

  backgroundImageUrl: any 
  defaultBackgroundColor: string = 'linear-gradient(to bottom, #667db6, #254ba8, #254ba8, #667db6), -webkit-linear-gradient(to bottom, #667db6, #254ba8, #254ba8, #667db6)';

  constructor(private router: Router, 
              private host_state: HostStateService,
              private ar: ActivatedRoute,
              ) {

    this.host_state.framework = this.ar.snapshot.paramMap.get('frame')  

    this.current_host_config = this.host_state.currentHostConfig
   // console.log('this.current_host_config ' + this.current_host_config?.theme.has_bg_img);
   // this.background_image = this.current_host_config?.theme.has_bg_img;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.current_pathname = event.urlAfterRedirects;
      } else {
        return;
      }
    });
  }
/*
  ngDoCheck() {
    this.backgroundImageUrl = this.background_image ? this.current_host_config?.theme?.background_url : '';      //check if there is background image to be used and set, elese empty
  }

  getBackgroundStyle() {

    if(this.background_image){
      return {
        'background-image':  `url(${this.backgroundImageUrl})`,
        'background-color':  'transparent'
      };
    } else {
        return {
          'background-image':  `none`,
          'background':  this.current_host_config?.theme?.background_color
        };
    }
  }
    */
}
