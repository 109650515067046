import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  Type,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReactionService, AppStateService, HostStateService, TelemetryService, getStepData, LandingService} from '@yoyo/services';
import { ReactionStepDirective, manualDelay } from '@yoyo/shared';

import {
  primaryLand,                          
  secondaryLand,
  base1,
  base2,
  base3,
  base4,
  base5,
  base6,
  base7,
  final,
  _baseComponent,
  DynamicComponent
} from '@yoyo/pages';

export class StepItem {
  constructor(public component: Type<any>) {}
}

@Component({
  selector: 'create-reaction',
  templateUrl: './create.component.html',
})
export class CreateReactionComponent implements OnInit {          
 
  activeReactionStep: number;
  @ViewChild(ReactionStepDirective, { static: true })
  reaction_step: ReactionStepDirective;
  reaction_steps = [
    primaryLand,                          
    secondaryLand,
    base1,
    base2,
    base3,
    base4,
    base5,
    base6,
    base7,
    final,                       
    _baseComponent,                     //keep me last
  ];

  isResolvingAppState = true;
  has_validation_error = false;
  host_available: boolean = false;

  constructor(
    private ar: ActivatedRoute,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appState: AppStateService,
    private rs: ReactionService,
    private host_state: HostStateService,
    private telemetry: TelemetryService,
    private stepDataService: getStepData,
    private landingService: LandingService
  ) {
    this.telemetry.startRecordingLoad()
    this.host_available = this.host_state.host_available;
    const stepNames = this.reaction_steps.map(step => step.name);
    this.stepDataService.setStepArray(stepNames);
  }

  async ngOnInit() {
  //  console.log('component init');
    this.telemetry.startRecordingUser()
    if (!this.host_available) {
      console.log('Host not valid in component');
      this.isResolvingAppState = false;
      this.appState.reactionStepValue$.next(15);
      this.setInitialStepIndex();
    } else {
      //await this.validateReactionId();
      this.isResolvingAppState = false;
      
      const landingPage = this.landingService.setAppLanding(this.appState.appScope)
      console.log('Landing on: ' + landingPage);
      const navTo = this.stepDataService.getStepNumber(landingPage);
      this.appState.reactionStepValue$.next(navTo);

      this.setInitialStepIndex()
    }
  }

  private telemetryRecord(next: number) {
   // this.telemetry.stopRecordingLoad();
    //this.telemetry.stopRecording(-1, 'Load', next);
   // console.log('step telemetery is: ' + JSON.stringify(this.telemetry.stepTrackArray, null,2));
  }

  setInitialStepIndex() {
    this.telemetryRecord(0);
    this.appState.reactionStepValue$.subscribe((stepIndex) => {
      this.activeReactionStep = stepIndex;
      //console.log('loading step' + stepIndex);
      this.loadStepperComponent(stepIndex);
    });
  }

  loadStepperComponent(stepIndex: number) {
    const currentStep = this.reaction_steps[stepIndex];
    const viewContainerRef = this.reaction_step.viewContainerRef;
    viewContainerRef.clear();
    viewContainerRef.createComponent<DynamicComponent>(currentStep);
  }

}
