import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { AppHost, textAttributes, propertyAttributes, core, hostThemeV2 } from '@yoyo/types';
import { environment } from '@yoyo/env';
import { Empty$, GetVal, GetVal$, SetVal } from '@yoyo/shared';
import { AuthService, hostCloudManagement } from '@yoyo/services';
import { Observable } from 'rxjs';
import { StepItem } from '../pages/create/create.component';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class HostStateService  {
  private host_config = Empty$<AppHost>('host_config');
  private host_name = Empty$<string>('host_name');
  host_available: boolean = false;
  private reactionId: string ="";
  framework: string = "v1";
  private fontURL: string;
  logoURL: string;

  constructor(  private http: HttpClient,
    private authService: AuthService,
    private ar: ActivatedRoute,
    private hostCloudManagement: hostCloudManagement
  ) { 
      this.framework = this.ar.snapshot.paramMap.get('frame')  
    }
  


  async getHostDataFromCloud(hostName: string, collection: string): Promise<any> {
    try {
      const response = await this.hostCloudManagement.retrieveHost(hostName, collection);
      if (response) {
        if (response.status) {
          if (response.status === 'ok') {
            if (response.payload) {
              return response.payload;
            } else {
              console.warn('getHostDataFromCloud fault no payload. Msg from server: ' + response.msg);
              return false;
            }
          } else {
            console.warn('getHostDataFromCloud fault status not ok. Server status: ' + response.status + ' Msg from server: ' + response.msg);
            return false;
          }
        } else {
          console.warn('getHostDataFromCloud fault no status in response.');
          return false;
        }
      } else {
        console.warn('getHostDataFromCloud fault no response.');
        return false;
      }
    } catch (error) {
      console.log('err: ' + JSON.stringify(error,null,2))
      if (this.isHttpErrorResponse(error)) {
        if (typeof error.error === 'string') {
          console.warn('getHostDataFromCloud HTML error response: ' + error.message);
        } else {
          const { status, msg, type, payload } = error.error;
          console.warn(`getHostDataFromCloud fault status error: status: ${status}, msg: ${msg}, type: ${type}, payload: ${payload}`);
        }
      } else {
        console.warn('getHostDataFromCloud unknown error: ' + JSON.stringify(error));
      }
      return false;
    }
  }
    
  //All components use this 
    isHttpErrorResponse(error: any): error is { status: number; message: string; error: { status: string; msg: string; type: string; payload: any } } {
      return error && error.status !== undefined && error.message !== undefined && error.error !== undefined;
    }
    
    async applyConfiguration(hostData: AppHost){
   //   console.log('init applyConfiguration: ' + JSON.stringify(hostData,null,2))
      this.setHostConfig(hostData);
      this.setHostName(hostData.name);
      console.log('setHostConfig complete');
     // this.setLocalStyleClasses(hostData);
      this.logoURL = hostData?.themev2?.coreStyles?.logo
      const { uniqueFonts, heading, body, info, overlay, prmy_btn, accent_btn, thrd_btn, common } = this.checkAndSetStyles(hostData?.themev2);
      this.loadDynamicFonts(uniqueFonts);
      this.loadDynamicAttributes( heading, body, info, overlay, prmy_btn, accent_btn, thrd_btn, common);
      this.host_available = true;
    }



  checkAndSetStyles(newStyle: hostThemeV2): { uniqueFonts: string[],
    heading: textAttributes, 
    body: textAttributes,
    info: textAttributes,
    overlay: propertyAttributes,
    prmy_btn: propertyAttributes,
    accent_btn: propertyAttributes,
    thrd_btn: propertyAttributes,
    common: core,
   }{
  const defaultFonts = {
    defaultFont: "Roboto",
    headingFont: "Roboto",
    accent_btnFont: "Roboto",
    bodyFont: "Roboto",
    infoFont: "Roboto",
    overlayFont: "Roboto",
    primary_btnFont: "Roboto",
    thrd_btnFont: "Roboto"
  };

  const defaultWeights = {
    heading: "400",
    body: "400",
    info: "400",
    overlay: "400",
    accent_btn: "400",
    primary_btn: "400",
    thrd_btn: "400"
  };

  const defaultTxtColors = {
    heading: "#000000",
    body: "#000000",
    info: "#000000",
    overlay: "#000000",
    accent_btn: "#000000",
    prmy_btn: "#000000",
    thrd_btn: "#000000"
  }

  const defaultBtnFill = {
    accent_btn: "#000000",
    prmy_btn: "#000000",
    thrd_btn: "#000000",
    overlay: "#000000"
  }

  const styles = newStyle.elementStyles|| {};
  const core = newStyle.coreStyles|| {};

  // Determine the default font
  const defaultFont = styles.heading?.font_family || environment.fontDefault || defaultFonts.defaultFont;

  // Use the default font for all other fonts if they are not provided
  const fontSettings = {
    defaultFont: defaultFont,
    headingFont: styles?.heading?.font_family || defaultFont,
    bodyFont: styles?.body?.font_family || defaultFont,
    infoFont: styles?.info?.font_family || defaultFont,
    overlayFont: styles?.overlay?.font_family || defaultFont,
    primary_btnFont: styles?.prmy_btn?.font_family || defaultFont,
    accent_btnFont: styles?.accent_btn?.font_family || defaultFont,
    thrd_btnFont: styles?.thrd_btn?.font_family || defaultFont
    };

  const heading: textAttributes = {
    font_family: styles?.heading?.font_family || defaultFont,
    font_weight: styles?.heading?.font_weight || environment?.styling?.heading?.font_weight || defaultWeights.heading,
    text_color: styles?.heading?.text_color || environment?.styling?.heading?.text_color || defaultTxtColors.heading,
    }

  const body: textAttributes = {
    font_family: styles?.body?.font_family || defaultFont,
    font_weight: styles?.body?.font_weight || environment.styling.body.font_weight || defaultWeights.body,
    text_color: styles?.body?.text_color || environment.styling.body.text_color || defaultTxtColors.body,
    }

  const info: textAttributes = {
    font_family: styles?.info?.font_family || defaultFont,
    font_weight: styles?.info?.font_weight || environment.styling.info.font_weight || defaultWeights.info,
    text_color: styles?.info?.text_color || environment.styling.info.text_color || defaultTxtColors.info,
    }

  const overlay: propertyAttributes = {
    font_family: styles?.overlay?.font_family || '',
    font_weight: styles?.overlay?.font_weight || '',
    text_color: styles?.overlay?.text_color || '',
    fill: styles?.overlay?.fill|| '',
    border_color: styles?.overlay?.border_color || '',
    border_style: styles?.overlay?.border_style || '',
    border_width: styles?.overlay?.border_width || '',
    shadow: { output:styles?.overlay?.shadow?.output || ''},
  }

  const prmy_btn: propertyAttributes = {
    font_family: styles?.prmy_btn?.font_family || defaultFont,
    font_weight: styles?.prmy_btn?.font_weight || environment.styling.prmy_btn.font_weight || defaultWeights.primary_btn,
    text_color: styles?.prmy_btn?.text_color || environment.styling.prmy_btn.text_color || defaultTxtColors.prmy_btn,
    fill: styles?.prmy_btn?.fill || environment.styling.prmy_btn.fill || defaultBtnFill.prmy_btn,
    border_color: styles?.prmy_btn?.border_color || environment.styling.prmy_btn.border_color,
    border_style: styles?.prmy_btn?.border_style || '',
    border_width: styles?.prmy_btn?.border_width || '',
    shadow: { output:styles?.prmy_btn?.shadow?.output ||''},
    }

  const accent_btn: propertyAttributes = {
    font_family: styles?.accent_btn?.font_family || defaultFont,
    font_weight: styles?.accent_btn?.font_weight || environment.styling.accent_btn.font_weight || defaultWeights.accent_btn,
    text_color: styles?.accent_btn?.text_color || environment.styling.accent_btn.text_color || defaultTxtColors.accent_btn,
    fill: styles?.accent_btn?.fill || environment.styling.accent_btn.fill || defaultBtnFill.accent_btn,
    border_color: styles?.accent_btn?.border_color || environment.styling.accent_btn.border_color,
    border_style: styles?.accent_btn?.border_style || '',
    border_width: styles?.accent_btn?.border_width || '',
    shadow: { output:styles?.accent_btn?.shadow?.output || '', },
    }

  const thrd_btn: propertyAttributes = {
    font_family: styles?.thrd_btn?.font_family || defaultFont,
    font_weight: styles?.thrd_btn?.font_weight || environment.styling.thrd_btn.font_weight || defaultWeights.thrd_btn,
    text_color: styles?.thrd_btn?.text_color || environment.styling.thrd_btn.text_color || defaultTxtColors.thrd_btn,
    fill: styles?.thrd_btn?.fill || environment.styling.thrd_btn.fill || defaultBtnFill.thrd_btn,
    border_color: styles?.thrd_btn?.border_color || environment.styling.thrd_btn.border_color,
    border_style: styles?.thrd_btn?.border_style || '',
    border_width: styles?.thrd_btn?.border_width || '',
    shadow: { output:styles?.thrd_btn?.shadow?.output || ''},
    }

    let bgURL = ''
    let bgColor = ''

  //console.log('newstyle of type core is : ' + JSON.stringify(core, null,2));

    if (core.background_url){
      bgColor = 'transperent';
      if (!core?.background_url?.startsWith('url')) {
        bgURL = "url('" + core.background_url + "')";
      }
    } else {
      bgColor = core?.background_color;
      bgURL = 'none'
    }

   //console.log('bgColor: ' + bgColor + ' bgURL: ' + bgURL);

  const common: core = {
      logo: core?.logo,
      background_url: bgURL,
      background_color: bgColor,
      header_bg_color: core?.header_bg_color,
      panel_color: core?.panel_color
    }


  // Return unique font settings and the complete style settings JSON
  return {
  uniqueFonts: [...new Set(Object.values(fontSettings))],
  heading,
  body,
  info,
  overlay,
  prmy_btn,
  accent_btn,
  thrd_btn,
  common
  };
}
  
  loadDynamicFonts(uniqueFonts: string[]) {
    const linkElement = document.createElement('link');
    linkElement.setAttribute('rel', 'stylesheet');
    linkElement.setAttribute('type', 'text/css');
  
    // Construct the URL for Google Fonts
    const fontFamilies = uniqueFonts.map(font => `family=${font.replace(/\s+/g, '+')}`).join('&');
    const fontURL = `https://fonts.googleapis.com/css2?${fontFamilies}&display=swap`;

    // Log the constructed URL for verification
   // console.log(fontURL);

    // Set the href attribute of the link element to the constructed URL
    linkElement.setAttribute('href', fontURL);

    // Append the link element to the head
    document.head.appendChild(linkElement);
  }

  loadDynamicAttributes(heading: textAttributes,
    body: textAttributes,
    info: textAttributes,
    overlay: propertyAttributes,
    prmy_btn: propertyAttributes,
    accent_btn: propertyAttributes,
    thrd_btn: propertyAttributes,
    common?: core)
  
    {
    //console.log('overlay is: ' + JSON.stringify(overlay, null,2));
    const linkElement = document.createElement('link');
    linkElement.setAttribute('rel', 'stylesheet');
    linkElement.setAttribute('type', 'text/css');
    //heading
    this.setCSSVariable('--heading-font', heading.font_family);
    this.setCSSVariable('--heading-font-weight', heading.font_weight);
    this.setCSSVariable('--heading-color', heading.text_color);

    //body
    this.setCSSVariable('--body-font', body.font_family);
    this.setCSSVariable('--body-font-weight', body.font_weight);
    this.setCSSVariable('--body-color', body.text_color);

    //info
    this.setCSSVariable('--info-font', info.font_family);
    this.setCSSVariable('--info-font-weight', info.font_weight);
    this.setCSSVariable('--info-color', info.text_color);

    //overlay
    this.setCSSVariable('--overlay-font', overlay.font_family);
    this.setCSSVariable('--overlay-font-weight', overlay.font_weight);
    this.setCSSVariable('--overlay-color', overlay.text_color);
    this.setCSSVariable('--overlay-fill', overlay.fill);
    this.setCSSVariable('--overlay-border-color', overlay.border_color);
    this.setCSSVariable('--overlay-border-style', overlay.border_style);
    this.setCSSVariable('--overlay-border-width', overlay.border_width);
    this.setCSSVariable('--overlay-shadow', overlay.shadow.output);

    //prmy_btn
    this.setCSSVariable('--prmy_btn-font', prmy_btn.font_family);
    this.setCSSVariable('--prmy_btn-font-weight', prmy_btn.font_weight);
    this.setCSSVariable('--prmy_btn-color', prmy_btn.text_color);
    this.setCSSVariable('--prmy_btn-fill', prmy_btn.fill);
    this.setCSSVariable('--prmy_btn-border-color', prmy_btn.border_color);
    this.setCSSVariable('--prmy_btn-border-style', prmy_btn.border_style);
    this.setCSSVariable('--prmy_btn-border-width', prmy_btn.border_width);
    this.setCSSVariable('--prmy_btn-shadow', prmy_btn.shadow.output);

    //accent_btn
    this.setCSSVariable('--accent_btn-font', accent_btn.font_family);
    this.setCSSVariable('--accent_btn-font-weight', accent_btn.font_weight);
    this.setCSSVariable('--accent_btn-color', accent_btn.text_color);
    this.setCSSVariable('--accent_btn-fill', accent_btn.fill);
    this.setCSSVariable('--accent_btn-border-color', accent_btn.border_color);
    this.setCSSVariable('--accent_btn-border-style', accent_btn.border_style);
    this.setCSSVariable('--accent_btn-border-width', accent_btn.border_width);
    this.setCSSVariable('--accent_btn-shadow', accent_btn.shadow.output);

    //thrd_btn
    this.setCSSVariable('--thrd_btn-font', thrd_btn.font_family);
    this.setCSSVariable('--thrd_btn-font-weight', thrd_btn.font_weight);
    this.setCSSVariable('--thrd_btn-color', thrd_btn.text_color);
    this.setCSSVariable('--thrd_btn-fill', thrd_btn.fill);
    this.setCSSVariable('--thrd_btn-border-color', thrd_btn.border_color);
    this.setCSSVariable('--thrd_btn-border-style', thrd_btn.border_style);
    this.setCSSVariable('--thrd_btn-border-width', thrd_btn.border_width);
    this.setCSSVariable('--thrd_btn-shadow', thrd_btn.shadow.output);

    this.setCSSVariable('--header-bg-color', common.header_bg_color);
    this.setCSSVariable('--systemBG', common.background_url);
    this.setCSSVariable('--systemBGColor', common.background_color);
    this.setCSSVariable('--panel-bg-color', common.panel_color);
  }
  
  setCSSVariable (propertyName: string, value: string) {
  //  console.log('value for ' + propertyName + ' is: ' + value);
    if (value) {
    //  console.log('value for ' + propertyName + ' is: ' + value);
      document.documentElement.style.setProperty(propertyName, value);
    }
  }
  

  
  async retriveHostConfig(host_name: string) {
    //hostname check for netifly to allow use of localhost config from firebase
    if (host_name.includes('netlify.app')) {
      console.log('Netilfy has been detected and the hostname has been changed to localhost.');
      console.log('Add reaction id to the URL');
      host_name = 'localhost';
    };
    try {
      
      console.log('Getting host: ' + host_name);
      const authToken =  'Bearer ' + this.authService.getAuthToken();
      //console.log('authToken ' + authToken);
      const response = await this.http
        .post<AppHost>(
          environment.api.host.retrieve,
          {
            host_name,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization' : authToken,
            },
            responseType: 'json',
          }
        )
        .toPromise();
   //   console.log('Got a host: ' + JSON.stringify(response, null, 2));
      // If the request is successful, set host_available to true
      this.host_available = true;

      return response;
    } catch (e) {
      console.log('error is: ' + JSON.stringify(e, null, 2));
      this.host_available = false;
      throw e;
    }
}

  setHostConfig(host_config: AppHost): void {
    SetVal(this.host_config, host_config);
  }

  get currentHostConfig(): AppHost {
    return GetVal(this.host_config);
  }
  get currentHostConfig$(): Observable<AppHost> {
    return GetVal$(this.host_config);
  }

  setHostName(host_name: string): void {
    SetVal(this.host_name, host_name);
  }

  get currentHostName(): string {
    return GetVal(this.host_name);
  }
  get currentHostName$(): Observable<string> {
    return GetVal$(this.host_name);
  }
}
